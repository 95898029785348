<template>
  <div class="auth-wrapper auth-v1 px-2 forgot-password-page">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <AppLogoWithText />
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            v-show="!hideForm"
            class="auth-forgot-password-form mt-2"
            @submit.prevent="onSubmit"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <vue-programmatic-invisible-google-recaptcha
              ref="recaptcha"
              element-id="recaptcha"
              badge-position="right"
              :sitekey="siteKey"
              :show-badge-mobile="false"
              :show-badge-desktop="true"
              @recaptcha-callback="recaptchaCallback"
            />
            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              <div v-if="isLoading">
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  small
                  label="Small Spinner"
                  type="grow"
                />
              </div>
              <div v-else>
                Send reset link
              </div>
            </b-button>
          </b-form>

          <b-alert
            v-show="message"
            class="mt-2"
            :variant="alertVariant"
            show
          >
            <div class="alert-body">
              <span>{{ message }}</span>
            </div>
          </b-alert>

        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link
            class="tw-flex tw-items-center tw-justify-center"
            :to="{name:'auth-login'}"
          >
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'
import AppLogoWithText from '@/components/AppLogoWithText.vue'

export default {
  components: {
    AppLogoWithText,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    VueProgrammaticInvisibleGoogleRecaptcha,
  },
  data() {
    const { appTextLogoImage } = $themeConfig.app
    return {
      siteKey: '6LfM4KMkAAAAAASqDOHDphXk-Dd7tFVrKQ_xGF1t',
      userEmail: '',
      message: null,
      recaptchaResponseToken: null,
      alertVariant: 'success',
      isLoading: false,
      // validation
      required,
      email,
      appTextLogoImage,
    }
  },
  computed: {
    hideForm() {
      return this.message !== null && this.alertVariant === 'success'
    },
  },
  methods: {
    onSubmit() {
      if (process.env.NODE_ENV === 'local') {
        this.validationForm()
        return
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$refs.recaptcha.execute()
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$store.dispatch('auth/forgotPassword', {
            email: this.userEmail,
            recaptcha: process.env.NODE_ENV === 'local' ? 'letmein#' : this.recaptchaResponseToken,
          }).then(result => {
            this.alertVariant = 'success'
            this.message = result
          }).catch(e => {
            this.alertVariant = 'danger'
            this.message = e.response.data.message
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
    handleError() {
      this.$refs.simpleRules.setErrors({
        email: ['Captcha is required'],
      })
    },
    recaptchaCallback(token) {
      if (token) {
        this.recaptchaResponseToken = token
        this.validationForm()
      } else {
        this.handleError()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
